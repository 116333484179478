import { APIFilterOP } from '@jagu/rest-api-filters-client/apiFilters'
import { NuxtLocale } from '~/constants/locales'
import { convertNuxtLocale } from '~/utils/i18n'

export {
  APIFilters,
  APIFilterOP,
  APIFilterDataType,
  APIFilterDataTypeAllowedOperations,
} from '@jagu/rest-api-filters-client/apiFilters'

function getBaseFilter(visible: boolean): Array<any> {
  const visibilityFilter = getVisibilityFilter(visible)

  return [...visibilityFilter]
}

function getVisibilityFilter(visible: boolean): Array<any> {
  return [
    {
      [APIFilterOP.EQUALS]: {
        publicStatus: visible ? 1 : 0,
      },
    },
    {
      [APIFilterOP.EQUALS]: {
        status: 1,
      },
    },
  ]
}

function getStatusFilter() {
  return {
    [APIFilterOP.EQUALS]: {
      status: 1,
    },
  }
}

function getPublicStatusFilter() {
  return {
    [APIFilterOP.EQUALS]: {
      publicStatus: 1,
    },
  }
}

function addFilter(
  filterKey: string,
  value: string | number,
  currentFilter?: any
) {
  const newFilter = {
    [APIFilterOP.EQUALS]: {
      [filterKey]: value,
    },
  }
  if (currentFilter === undefined) {
    return newFilter
  }

  currentFilter.push(newFilter)
}

function addNotEqualsFilter(
  filterKey: string,
  value: string | number,
  currentFilter?: any
) {
  const newFilter = {
    [APIFilterOP.NOT_EQUALS]: {
      [filterKey]: value,
    },
  }
  if (currentFilter === undefined) {
    return newFilter
  }

  return {
    [APIFilterOP.AND]: [currentFilter, newFilter],
  }
}

export {
  getBaseFilter,
  getVisibilityFilter,
  getStatusFilter,
  getPublicStatusFilter,
  addNotEqualsFilter,
  addFilter,
}
