import type { NotificationDetail, NotificationPagedResult } from '~/types/api'

export function createNotificationsRepository() {
  const { $api, $i18n } = useNuxtApp()
  const repository = baseRepository($api)

  const DOMAIN = 'notifications'

  return {
    async getAll(params?: QueryParams) {
      if (!params) {
        params = {
          filter: APIFilters.makeFilter(getBaseFilter(true)),
        }
      }
      return repository.get<NotificationPagedResult>([DOMAIN], { params })
    },
    async getCurrentForRoute(route: string) {
      const filter = getBaseFilter(true)
      const currentISODate = new Date().toISOString()
      filter.push(
        ...[
          {
            [APIFilterOP.LOWER_THAN_OR_EQUAL]: {
              showSince: currentISODate,
            },
          },
          {
            [APIFilterOP.GREATER_THAN_OR_EQUAL]: {
              showUntil: currentISODate,
            },
          },
          {
            [APIFilterOP.OR]: [
              {
                [APIFilterOP.ARRAY_EMPTY]: 'showAt',
              },
              {
                [APIFilterOP.EQUALS]: {
                  'showAt.url': route,
                },
              },
            ],
          },
        ]
      )
      const params = {
        filter: APIFilters.makeFilter(filter),
      }
      return repository.get<NotificationPagedResult>([DOMAIN], { params })
    },
    async get(id: number) {
      return repository.get<NotificationDetail>([DOMAIN, id])
    },
  }
}
