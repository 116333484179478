import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const userStore = useUser()
  const { refresh } = userStore
  const { $i18n } = useNuxtApp()
  const { locale } = $i18n
  const { accessToken } = storeToRefs(userStore)

  const config = useRuntimeConfig()
  const $api = $fetch.create({
    baseURL: config.public.apiUrl,
    async onRequest({ options, request }) {
      // Add token if possible
      options.headers = accessToken.value
        ? {
            ...options.headers,
            Authorization: `Bearer ${accessToken.value}`,
          }
        : options.headers
      if (options.headers) {
        options.headers!['Accept-Language'] = locale.value
      } else {
        options.headers = {
          'Accept-Language': locale.value,
        }
      }
      // Refresh user info if refresh token is available
      options = await refresh(request.toString(), options)
    },
    onResponseError({ response }) {
      if (!response.ok && response.status !== 404 && response.status !== 422) {
        const errorData = {
          status: response.status,
          statusText: response.statusText,
          url: response.url,
          redirected: response.redirected,
          data: response._data,
          headers: response.headers,
        }
        Sentry.captureException(`C# API ${response.statusText}`, {
          extra: errorData,
        })
        console.error(errorData)
      }
    },
  })
  // Expose to useNuxtApp().$api
  return {
    provide: {
      api: $api,
    },
  }
})
